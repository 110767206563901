import(/* webpackMode: "eager", webpackExports: ["UAProvider"] */ "/var/www/html/b2-ming-tak-web-live/app/context/useUA.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/var/www/html/b2-ming-tak-web-live/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SecurityPinProvider"] */ "/var/www/html/b2-ming-tak-web-live/components/modals/security-pin.tsx");
;
import(/* webpackMode: "eager" */ "/var/www/html/b2-ming-tak-web-live/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/b2-ming-tak-web-live/styles/style.css");
;
import(/* webpackMode: "eager" */ "/var/www/html/b2-ming-tak-web-live/node_modules/.pnpm/next@14.2.19_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"config/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-sans\"}],\"variableName\":\"fontSans\"}");
;
import(/* webpackMode: "eager" */ "/var/www/html/b2-ming-tak-web-live/node_modules/.pnpm/next@14.2.19_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/font/google/target.css?{\"path\":\"config/fonts.ts\",\"import\":\"Fira_Code\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-mono\"}],\"variableName\":\"fontMono\"}");
